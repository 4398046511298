import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';

async function deleteCoverRequest({ requestId, cancelAll }) {
  const query = new URLSearchParams();
  if (cancelAll) query.set('cancelAll', cancelAll);
  const response = await fetch(`/api/cover/${requestId}?${query}`, {
    method: 'DELETE',
  });
  const responseBody = await response.json();
  if (!response.ok) throw new Error(responseBody.message);
  return responseBody;
}

export default function useDeleteRequest() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(deleteCoverRequest, {
    onMutate: async (variables) => {
      await queryClient.cancelQueries('dashboard');
      const previousDashboard = queryClient.getQueryData('dashboard');
      if (!previousDashboard) return;
      const dateToFilter = moment(variables.date);
      const optimisticDashboard = {
        ...previousDashboard,
        requestedCover: previousDashboard.requestedCover.filter((request) => {
          if (variables.cancelAll) {
            return (
              request.assignedTo.length > 0 ||
              !moment(request.startDateTime).isSame(dateToFilter, 'day')
            );
          }
          return request.id !== variables.requestId;
        }),
      };
      queryClient.setQueryData('dashboard', optimisticDashboard);
      return previousDashboard;
    },
    onError: (error, variables, previousValue) => {
      queryClient.setQueryData('dashboard', previousValue);
      enqueueSnackbar(
        error.message ?? 'Failed to cancel cover, please try again later.',
        {
          variant: 'error',
        }
      );
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries('dashboard');
      queryClient.invalidateQueries('timetable');
      queryClient.invalidateQueries('frees');
      const date = moment(data.startDateTime).format('ddd DD MMM');
      const period = data.entry.timetablePeriod
        ? data.entry.timetablePeriod.name.split(':')[1]
        : `${data.entry.startDateTime.toLocaleString()}
          - ${data.entry.endDateTime.toLocaleString()}`;
      let message = `Cover cancelled: ${date} - Period ${period}`;
      if (variables.cancelAll) {
        message = `Cover cancelled: ${date} - All Periods`;
      }
      enqueueSnackbar(message, { variant: 'success' });
    },
  });
}
