import { useQuery } from 'react-query';

async function fetchUpcomingRequests(userId) {
  const query = new URLSearchParams();
  if (userId) query.set('userId', userId);
  const response = await fetch(`/api/cover/upcoming?${query}`);
  const responseBody = await response.json();
  if (!response.ok) throw new Error(responseBody.message);
  return responseBody;
}

export default function useUpcomingRequests(userId) {
  return useQuery(['upcoming', userId], () => fetchUpcomingRequests(userId));
}
