import { useQuery } from 'react-query';

async function fetchStats() {
  const response = await fetch('/api/stats');
  const responseBody = await response.json();
  if (!response.ok) throw new Error(responseBody.message);
  return responseBody;
}

export default function useStats() {
  return useQuery('stats', fetchStats);
}
